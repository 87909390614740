.header {
  padding: 1em;
}

.header a {
  color: black;
  transition: 0.5s ease-in-out;
}

.header a:hover {
  color: rgba(0, 0, 0, 0.52);
  text-decoration: none;
}

.dropdown-menu a {
  padding: 1em;
}

.settings-button {
  font-size: 22px !important;
}

.active-locale-button {
  box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
  border-radius: 6px;
}

.settings-button::after {
  display: none !important;
}

.fa-icon {
  color: rgba(0, 0, 0, 0.7);
}
